<template>
	<div class="sector-map" :style="mapStyle">
		<span class="coords">{{ `${center.x},${center.y}` }}</span>
		<canvas v-on:click="mapClick" ref="canvas" id="canvas" :width="this.mapSize*this.tileSize*2" :height=this.mapSize*this.tileSize*2></canvas>
	</div>
</template>

<script>
	import Land from "@/js/api/land"
	
	export default {
		name: "SectorMap",
		props: {
			loadSurroundingTiles: { //If enabled will load the sector tiles around the current sector
				type: Boolean,
				default: function () {
					return false
				}
			},
			center: {
				type: Object,
				required: true,
			},
			extraTiles: {
				type: Array
			},
			tiles: {
				type: Array
			},
			tileLength: {
				type: Number
			},
			mapSize: {
				type: Number,
				default: function() {return 10}
			},
			tileSize: {
				type: Number,
				default: function() {return 15}
			},
		},
		data() {
			return {
				loadedTiles: [],
				map: [],
				ctx: null,
			}
		},
		watch: {
			tiles: function() {
                this.drawMap();
            },
            center: function() {
                this.drawMap();
            },
        },
		computed: {
			tileList: function() {
				if (!this.loadSurroundingTiles)
					return this.tiles
				
				return this.loadedTiles
			},
			
			mapStyle: function () {
				//:width="this.mapSize*this.tileSize*2" :height=this.mapSize*this.tileSize*2
				return {
					width: `${this.mapSize * this.tileSize * 2}px`,
					height: `${this.mapSize * this.tileSize * 2}px`,
				}
			},
			sectorTop: function () {
				let x = this.center.x - this.mapSize;
				let y = this.center.y - this.mapSize;
				
				return [x, y]
			},
			mappedTiles: function() {
				let mt = {};
				for (let i = 0; i < this.tileList.length; i++) {
                    const tile = this.tileList[i];
                    mt[`${tile.x}|${tile.y}`] = tile;
                }
				
				return mt
			},
			mappedExtraTiles: function() {
				let mt = {};
				for (let i = 0; i < this.extraTiles.length; i++) {
                    const tile = this.extraTiles[i];
                    mt[`${tile.x}|${tile.y}`] = tile;
                }
				
				return mt
			}
		},
		methods: {
			bindContext: function() {
				// var c = document.getElementById("canvas");
				
				var c = this.$refs.canvas;
				this.ctx = c.getContext("2d");
				this.ctx.fillStyle = "blue";
				this.ctx.strokeStyle = "black";
			},
			
			buildMap: function() {
				console.log("Map Size: ", this.mapSize);
				for (let x = 0; x <= this.mapSize * 2; x++) {
					this.map[x] = [];
					for (let y = 0; y <= this.mapSize * 2; y++)
						this.map[x][y] = `${x - this.mapSize}|${y - this.mapSize}`
				}
			},
			
			drawMap: function () {
				this.ctx.clearRect(0, 0, this.mapSize*this.tileSize*2, this.mapSize*this.tileSize*2);
				for (let x = 0; x < this.map.length; x++) {
					for (let y = 0; y < this.map[x].length; y++) {
						this.ctx.fillStyle = this.color(x, y);
						this.draw(x * this.tileSize, y * this.tileSize, 1);
					}
				}
			},
			
			mapClick: function (e) {
				console.log("Click", e);
				var rect = e.target.getBoundingClientRect();
				var x = e.clientX - rect.left; //x position within the element.
				var y = e.clientY - rect.top;  //y position within the element.
				
				let tx = Math.floor(x / this.tileSize);
				let ty = Math.floor(y / this.tileSize);
				
				console.log(`Tile: ${tx},${ty}`);
				
				const chunk = this.toChunk(x / this.tileSize, y / this.tileSize);
				
				console.log("Clicked", `${this.sectorTop[0] + chunk[0]}|${this.sectorTop[1] + chunk[1]}`);
			},
			
			
			toChunk(x, y) {
				// console.log(`ToChunk: ${Math.floor(x) / this.tileSize},${Math.floor(y) / this.tileSize} - ${this.mapSize}`);
				const b = this.mapSize; //Middle out
				return [Math.floor(x - b), Math.floor(y - b)]
			},
			
			draw(x, y, size) {
				const border = (this.tileSize / 100) * 3;
				this.ctx.fillRect(x + border, y + border, this.tileSize * size - (border * 2), this.tileSize * size - (border * 2));
			},
			
			color: function (x, y) {
				//0|0
				
				const tx = this.sectorTop[0] + x
				// const tx = x
				const ty = this.sectorTop[1] + y
				// const ty = y
				
				if (this.center.x === tx && this.center.y === ty) {
					return "rgb(0,255,0)"
				}
				
				const foundExtraTile = this.mappedExtraTiles[`${tx}|${ty}`]
				if (foundExtraTile) {
					return "#a84510"
				}
				
				const foundTile = this.mappedTiles[`${tx}|${ty}`]
				if (!foundTile) {
					return "rgb(0,0,0)"
				}
				
				if (foundTile.owner_addr === this.$store.state.wallet.address) {
					return "rgb(0,50,0)"
				}
				
				if (foundTile.Buildings && foundTile.Buildings.length > 0) {
					return "#26B6D4"
				}
				
				if (foundTile.owner_addr === "")
					return "rgb(110,110,110)" //Not owned
				else
					return "#882caf" //Found but not owned by the user
			},
			
			loadSector: function () {
				const sx = this.center.x
				const sy = this.center.y
				console.log(`Loading tiles ${sx},${sy}`);
				Land.indexSector(sx - this.mapSize, sy - this.mapSize, this.mapSize * 2).then(resp => {
					console.log("Adding land: ", resp.data.length);
					this.loadedTiles = resp.data;
					this.drawMap();
				})
			}
		},
		mounted() {
			this.bindContext();
			this.buildMap();
			
			if (this.loadSurroundingTiles)
				this.loadSector()
			else
				setTimeout(this.drawMap, 20);
		}
	}
</script>

<style scoped>
	.sector-map {
		position: relative;
		margin: auto;
	}
	
	.coords {
		position: absolute;
		top: 5px;
		right: 5px;
		font-size: 0.7em;
	}
</style>
