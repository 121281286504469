var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sector-map", style: _vm.mapStyle }, [
    _c("span", { staticClass: "coords" }, [
      _vm._v(_vm._s(`${_vm.center.x},${_vm.center.y}`)),
    ]),
    _vm._v(" "),
    _c("canvas", {
      ref: "canvas",
      attrs: {
        id: "canvas",
        width: this.mapSize * this.tileSize * 2,
        height: this.mapSize * this.tileSize * 2,
      },
      on: { click: _vm.mapClick },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }