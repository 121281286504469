var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        height: "30",
        viewBox: "0 0 71 71",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M33.691 0.000732422H17.1347L4.65169 26.6667C1.26888 33.893 -0.422518 37.5061 0.0899523 40.2662C0.540737 42.694 2.14951 44.6679 4.68569 45.905C7.56895 47.3113 12.2052 47.3113 21.4776 47.3113H33.6189L38.8537 36.1288C39.1356 35.5266 39.2766 35.2255 39.2339 34.9955C39.1963 34.7932 39.0622 34.6287 38.8509 34.5256C38.6106 34.4084 38.2243 34.4084 37.4516 34.4084H26.414C23.3232 34.4084 21.7778 34.4084 20.8167 33.9396C19.9713 33.5273 19.435 32.8693 19.2848 32.06C19.114 31.14 19.6778 29.9356 20.8054 27.5269L33.691 0.000732422Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M28.8027 65.5042H45.359L57.842 38.8383C61.2248 31.612 62.9163 27.9989 62.4038 25.2388C61.953 22.811 60.3442 20.8371 57.808 19.6C54.9248 18.1937 50.2886 18.1937 41.0162 18.1937L28.8748 18.1937L23.64 29.3762C23.3581 29.9784 23.2172 30.2795 23.2599 30.5095C23.2974 30.7118 23.4315 30.8763 23.6429 30.9794C23.8831 31.0966 24.2695 31.0966 25.0422 31.0966H36.0798C39.1706 31.0966 40.716 31.0966 41.677 31.5653C42.5224 31.9777 43.0587 32.6356 43.209 33.4449C43.3798 34.3649 42.816 35.5693 41.6884 37.9781L28.8027 65.5042Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              id: "filter0_i_1874_5531",
              x: "88.5717",
              y: "6.51514",
              width: "355.251",
              height: "64",
              filterUnits: "userSpaceOnUse",
              "color-interpolation-filters": "sRGB",
            },
          },
          [
            _c("feFlood", {
              attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
            }),
            _vm._v(" "),
            _c("feBlend", {
              attrs: {
                mode: "normal",
                in: "SourceGraphic",
                in2: "BackgroundImageFix",
                result: "shape",
              },
            }),
            _vm._v(" "),
            _c("feColorMatrix", {
              attrs: {
                in: "SourceAlpha",
                type: "matrix",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                result: "hardAlpha",
              },
            }),
            _vm._v(" "),
            _c("feOffset"),
            _vm._v(" "),
            _c("feGaussianBlur", { attrs: { stdDeviation: "0.064" } }),
            _vm._v(" "),
            _c("feComposite", {
              attrs: {
                in2: "hardAlpha",
                operator: "arithmetic",
                k2: "-1",
                k3: "1",
              },
            }),
            _vm._v(" "),
            _c("feColorMatrix", {
              attrs: {
                type: "matrix",
                values: "0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0",
              },
            }),
            _vm._v(" "),
            _c("feBlend", {
              attrs: {
                mode: "normal",
                in2: "shape",
                result: "effect1_innerShadow_1874_5531",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }